import React, { useState } from 'react';
import { detailTypes } from './constant';
import { useDispatch } from 'react-redux';
import { updateType } from './actions/type'
import { useSelector } from 'react-redux';
import { updateCursor } from './actions/cursor';
import { baseAPI } from './constant';
import { updateItemsList } from './actions/items';
import { updateLoading } from './actions/loading';

const SubNav = ({item}) => {
    const [showSubNav, setShowSubNav] = useState(false);
    const dispatch = useDispatch();
    const searchKeyword = useSelector((state) => state.searchKeyword || '');
    const price = useSelector((state) => state.price|| {
        min: 0,
        max: 1000000,
      });
      let minPrice, maxPrice;
      if (price) {
        minPrice = price.min;
        maxPrice = price.max;
      }
    const exterior = useSelector((state) => state.exterior || '');


    const handleSubNavClick = (slug) => {
        dispatch(updateLoading(true));

        let treeFilters = exterior === '' ? `categoryPath[]=${slug}` : `${exterior},categoryPath[]=${slug}`;

        const fetchData = async () => {
            try {
              const response = await fetch(`${baseAPI}&title=${searchKeyword}&priceFrom=${minPrice}&priceTo=${maxPrice}&treeFilters=${treeFilters}`);
              console.log("fetch 4")
              const data = await response.json();
      
              if (response.status === 200) {
                dispatch(updateType(slug));
                dispatch(updateItemsList(data.objects));
                dispatch(updateCursor(data.cursor));
                console.log('Data fetched:', data);
                dispatch(updateLoading(false));
              } else {
                console.error('Error fetching data6:', data);
              }
            } catch (error) {
              console.error('Error fetching data7:', error);
            }
          };
      
          fetchData();
    }

    return(
        <div className="flex items-center h-full">
                        <ul className="lg:flex pl-4 font-normal">
                        <li className="relative" onMouseEnter={() => setShowSubNav(true)} onMouseLeave={() => setShowSubNav(false)} >
                            <a className="text-gray-400 p-3 hover:text-white flex items-center justify-center" href="/" onClick={(e) => e.preventDefault()}>
                                {item.name}
                            </a>
                            {showSubNav && (
                                <div className="absolute p-4 shadow-md rounded-md min-w-max top-[-4] bg-[#202020] pb-0">
                                <ul className="my-0 py-0">
                                    {detailTypes[item.detail].map((type) => (
                                        <li className="text-gray-400 mb-4">
                                            <div className="flex items-center">
                                                <img className="h-6 mr-2" src={type.imgSrc} alt="csgoexc"  />
                                                <span
                                                    className="hover:text-white  cursor-pointer"
                                                    key={type.slug} onClick={() => handleSubNavClick(type.slug)}
                                                >{type.spanText}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            )}  
                        </li>
                        </ul>
                    </div>
    )
}

export default SubNav;