import React, { useState } from 'react';
import { detailTypes } from './constant';
import { useDispatch } from 'react-redux';
import { updateType } from './actions/type'
import { useSelector } from 'react-redux';
import { updateCursor } from './actions/cursor';
import { baseAPI } from './constant';
import { updateItemsList } from './actions/items';
import { updateLoading } from './actions/loading';

const SmallSubNav = ({item}) => {
    const [showSmallSubNav, setShowSmallSubNav] = useState(false);
    const dispatch = useDispatch();
    const searchKeyword = useSelector((state) => state.searchKeyword || '');
    const price = useSelector((state) => state.price|| {
        min: 0,
        max: 10000,
      });
      let minPrice, maxPrice;
      if (price) {
        minPrice = price.min;
        maxPrice = price.max;
      }
    const exterior = useSelector((state) => state.exterior || '');

    const handleSubNavClick = (slug) => {
        dispatch(updateLoading(true));
        dispatch(updateType(slug));
        let treeFilters = exterior === '' ? `categoryPath[]=${slug}` : `${exterior},categoryPath[]=${slug}`;

        const fetchData = async () => {
            try {
              const response = await fetch(`${baseAPI}&title=${searchKeyword}&priceFrom=${minPrice}&priceTo=${maxPrice}&treeFilters=${treeFilters}`);
              console.log("fetch 4")
              const data = await response.json();
      
              if (response.status === 200) {
                dispatch(updateItemsList(data.objects));
                dispatch(updateCursor(data.cursor));
                console.log('Data fetched:', data);
                dispatch(updateLoading(false));
              } else {
                console.error('Error fetching data6:', data);
              }
            } catch (error) {
              console.error('Error fetching data7:', error);
            }
          };
      
          fetchData();
    }

    return(
        <div className="flex items-center h-full">
                        <ul className="flex font-normal">
                        <li className="relative flex" onMouseEnter={() => setShowSmallSubNav(true)} onMouseLeave={() => setShowSmallSubNav(false)} >
                            {showSmallSubNav && (
                                <div className="absolute p-4 shadow-md rounded-md w-48 bg-[#202020] pb-0 display-inline -left-48">
                                    <ul className="my-0 py-0">
                                        {detailTypes[item.detail].map((detail) => (
                                            <li className="text-gray-400 hover:text-white mb-4" key={detail.spanText}>
                                                <div className="flex items-center">
                                                    <img className="h-6 mr-2" src={detail.imgSrc} alt="csgoexc"/>
                                                    <span
                                                        className="hover:text-white  cursor-pointer"
                                                        key={detail.slug} onClick={() => handleSubNavClick(detail.slug)}
                                                    >{detail.spanText}</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}  
                            <a className="text-gray-400 p-3 hover:text-white flex" href="/" onClick={(e) => e.preventDefault()}>
                                {item.name}
                            </a>
                        </li>
                        </ul>
                    </div>
    )
}

export default SmallSubNav;