import React from 'react';
import Sidebar from './Sidebar';
import Items from './Items';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateItemsList } from './actions/items';
import { updateCursor } from './actions/cursor';

const MBody = () => {
  const loading = useSelector((state) => state.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
        console.log('fetching dataaaaaa');
      try {
        let response = await fetch("https://api.dmarket.com/exchange/v1/market/items?side=market&orderBy=personal&orderDir=desc&title=&priceFrom=0&priceTo=0&treeFilters=&gameId=a8db&types=dmarket&cursor=&limit=100&currency=USD&platform=browser&isLoggedIn=false")
        console.log("fetch 1")

        const data = await response.json();
        console.log('fetching data1: ', data);

        if (response.status === 200) {
          dispatch(updateItemsList(data.objects))
          dispatch(updateCursor(data.cursor))
          console.log('fetching data2: ', data.objects);
        } else {
          console.error('Error fetching data1:', data);
          console.log('error with response: ', response)
        }
      } catch (error) {
        console.error('Error fetching data2:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <div className="flex justify-center mt-16 mb-10">
        <Sidebar/>
        {loading ? 
          <div className="ml-0 sm:ml-80 -mt-20 w-60 h-screen flex justify-center items-center">
            <ReactLoading type="bubbles" color="#fff"  height={'80%'} width={'80%'} className='flex'></ReactLoading>
          </div> : 
          <Items/>
        }
    </div>
  );
};

export default MBody;
