// PercentageLine.jsx
import React from "react";

function PercentageLine({ value  }) {
    // const color = value > 0 ? '#8bcf48' : '#ff0000';
   
    return (
        <div className="grid grid-cols-9 gap-px mb-4 bg-gray-700">
            {/* {
                for (let i = 1; i <= 9; i++) {
                    <div className={`h-px bg-[#8bcf48]`} id={`pc-${i}`}></div>
                }
            } */}
            <div className={`h-px ${value >= 0 ? "bg-[#8bcf48]" : "bg-transparent"}`} id='pc-1'></div>
            <div className={`h-px ${value > 1 ? "bg-[#48cf7e]" : "bg-transparent"}`} id='pc-2'></div>
            <div className={`h-px ${value > 2 ? "bg-[#cf9948]" : "bg-transparent"}`} id='pc-3'></div>
            <div className={`h-px ${value > 3 ? "bg-[#cf7048]" : "bg-transparent"}`} id='pc-4'></div>
            <div className={`h-px ${value > 4 ? "bg-[#ef703a]" : "bg-transparent"}`} id='pc-5'></div>
            <div className={`h-px ${value > 5 ? "bg-[#f95008]" : "bg-transparent"}`} id='pc-6'></div>
            <div className={`h-px ${value > 6 ? "bg-[#f93a08]" : "bg-transparent"}`} id='pc-7'></div>
            <div className={`h-px ${value > 7 ? "bg-[#f90808]" : "bg-transparent"}`} id='pc-8'></div>
            <div className={`h-px ${value > 8 ? "bg-[#ff0000]" : "bg-transparent"}`} id='pc-9'></div>
      </div>
    )
  }
  

export default PercentageLine;
