import React from "react";
import { useSelector } from "react-redux";
import Card from "./Card";
import { useDispatch } from 'react-redux';
import { addItemsList } from './actions/items';
import { baseAPI } from "./constant";


const SmallItems = () => {
    const items = useSelector((state) => state.items);     
    const dispatch = useDispatch();

    console.log("items in small items", items)

    if (items.length === 0) {
        const fetchData = async () => {
            try {
              const response = await fetch(`${baseAPI}`);
              console.log("fetch 1")
              const data = await response.json();
      
              if (response.status === 200) {
                dispatch(addItemsList(data.objects));
                console.log('Data fetched:', data);
              } else {
                console.error('Error fetching data2:', data);
              }
            } catch (error) {
              console.error('Error fetching data3:', error);
            }
          };
      
          fetchData();
    }

    const shuffledArray = items.sort(() => Math.random() - 0.5);
    const onlyItems = shuffledArray.slice(0, 12);

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {onlyItems.map((item) => (
              <Card key={item.itemId} itemData={item} />
            ))}
        </div>
    )
}

export default SmallItems;