import SmallSubNav from "./SmallSubNav"
import { useState } from "react"
import { mainTypes } from "./constant"

const SmallNav = () => {
    const [showSmallSubNav, setShowSmallSubNav] = useState(false);
    const data = mainTypes

    return (
        <div className="relative block 2xl:hidden">
         {/* <div className="relative block"> */}
        <button className="h-10 text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-menu"
            onClick={() => setShowSmallSubNav(!showSmallSubNav)}
          >
            <line x1="4" x2="20" y1="12" y2="12"></line>
            <line x1="4" x2="20" y1="6" y2="6"></line>
            <line x1="4" x2="20" y1="18" y2="18"></line>
          </svg>
        </button>
        <div className="absolute top-0 left-0 z-10 invisible"></div>   
        {showSmallSubNav && (
          <div className="absolute top-12 right-0 z-20 bg-[#202020] 2xl:flex flex-col justify-center min-w-max shadow-md rounded-md p-2">
          {data.map((item) => {
              return (
                  <SmallSubNav item={item}/>
              )
          })}
      </div>    
        )}
      </div>
    )
}

export default SmallNav