export const updateItemsList = (items) => {
  return {
    type: 'UPDATE_ITEMS_LIST',
    payload: items
  }
}

export const addItemsList = (items) => { 
  return {
    type: 'ADD_ITEMS_LIST',
    payload: items
  }
}