export const baseAPI = "https://api.dmarket.com/exchange/v1/market/items?side=market&orderBy=personal&orderDir=desc&gameId=a8db&types=dmarket&limit=100&currency=USD&platform=browser&isLoggedIn=false"

export const mainTypes = [
    {
      name : "Rifles",
      url: "https://csgoexc.com/json/categories/rifles.json",
      detail: "riflesTypes"
    },
    {
      name: "Sniper Rifles",
      url: "https://csgoexc.com/json/categories/sniper-rifles.json",
      detail: "sniperRiflesTypes"
    },
    {
      name: "Pistols",
      url: "https://csgoexc.com/json/categories/pistols.json",
      detail: "pistolsType"
    },
    {
      name: "Knives",
      url: "https://csgoexc.com/json/categories/knives.json",
      detail: "knivesTypes"
    },
    {
      name: "Gloves",
      url: "https://csgoexc.com/json/categories/gloves.json",
      detail: "glovesTypes"
    },
    {
      name: "Machine Guns",
      url: "https://csgoexc.com/json/categories/machine-guns.json",
      detail: "machineGunsTypes"
    },
    {
      name: "SMGs",
      url: "https://csgoexc.com/json/categories/smgs.json",
      detail: "smgsTypes"
    },
    {
      name: "Shotguns",
      url: "https://csgoexc.com/json/categories/shotguns.json",
      detail: "shotgunsTypes"
    },
    // {
    //   name: "Agents",
    //   url: "https://csgoexc.com/json/categories/agents.json",
    //   detail: "agentsTypes"
    // },
    // {
    //   name: "Other",
    //   url: "https://csgoexc.com/json/categories/other.json",
    //   detail: "otherTypes"
    // }
]

export const detailTypes = {
riflesTypes: [
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934684/50fx50f",
      spanText: "AK-47",
      slug: "rifle/ak-47"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1354027367/50fx50f",
      spanText: "AUG",
      slug: "rifle/aug"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934438/50fx50f",
      spanText: "FAMAS",
      slug: "rifle/famas"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934368/50fx50f",
      spanText: "Galil AR",
      slug: "rifle/galil ar"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1017844483/50fx50f",
      spanText: "M4A1-S",
      slug: "rifle/m4a1-s"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934862/50fx50f",
      spanText: "M4A4",
      slug: "rifle/m4a4"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011935682/50fx50f",
      spanText: "SG 553",
      slug: "rifle/sg 553"
    }
  ],
sniperRiflesTypes: [
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1009256477/50fx50f",
      spanText: "AWP",
      slug: "sniper rifle/awp"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1310009716/50fx50f",
      spanText: "G3SG1",
      slug: "sniper rifle/g3sg1"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1309988348/50fx50f",
      spanText: "SCAR-20",
      slug: "sniper rifle/scar-20"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1310016805/50fx50f",
      spanText: "SSG 08",
      slug: "sniper rifle/ssg 08"
    }
  ],
pistolsType: [
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011935025/50fx50f",
      spanText: "CZ75-Auto",
      slug: "pistol/cz75-auto"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1084880341/50fx50f",
      spanText: "Desert Eagle"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1309995930/50fx50f",
      spanText: "Dual Berettas",
      slug: "pistol/dual berettas"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1373427114/50fx50f",
      spanText: "Five-SeveN",
      slug: "pistol/five-seven"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011950648/50fx50f",
      spanText: "Glock-18",
      slug: "pistol/glock-18"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011935012/50fx50f",
      spanText: "P2000",
      slug: "pistol/p2000"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1225582088/50fx50f",
      spanText: "P250",
      slug: "pistol/p250"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1432177230/50fx50f",
      spanText: "R8 Revolver",
      slug: "pistol/r8 revolver"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1241927135/50fx50f",
      spanText: "Tec-9",
      slug: "pistol/tec-9"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934384/50fx50f",
      spanText: "USP-S",
      slug: "pistol/usp-s"
    }
  ],
knivesTypes: [
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1812814373/50fx50f",
    spanText: "Bayonet",
    slug: "knife/bayonet"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1560435823/50fx50f",
    spanText: "Bowie Knife",
    slug: "knife/bowie knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2099637742/50fx50f",
    spanText: "Butterfly Knife",
    slug: "knife/butterfly knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3574238911/50fx50f",
    spanText: "Classic Knife",
    slug: "knife/classic knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011953270/50fx50f",
    spanText: "Falchion Knife",
    slug: "knife/falchion knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1118151362/50fx50f",
    spanText: "Flip Knife",
    slug: "knife/flip knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1812848282/50fx50f",
    spanText: "Gut Knife",
    slug: "knife/gut knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2220010298/50fx50f",
    spanText: "Huntsman Knife",
    slug: "knife/huntsman knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1351038798/50fx50f",
    spanText: "Karambit",
    slug: "knife/karambit"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1730574967/50fx50f",
    spanText: "M9 Bayonet",
    slug: "knife/m9 bayonet"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2961023426/50fx50f",
    spanText: "Navaja Knife",
    slug: "knife/navaja knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084638/50fx50f",
    spanText: "Nomad Knife",
    slug: "knife/nomad knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084476/50fx50f",
    spanText: "Paracord Knife",
    slug: "knife/paracord knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1310838538/50fx50f",
    spanText: "Shadow Daggers",
    slug: "knife/shadow daggers"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608091705/50fx50f",
    spanText: "Skeleton Knife",
    slug: "knife/skeleton knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2961838510/50fx50f",
    spanText: "Stiletto Knife",
    slug: "knife/stiletto knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608091240/50fx50f",
    spanText: "Survival Knife",
    slug: "knife/survival knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2961124963/50fx50f",
    spanText: "Talon Knife",
    slug: "knife/talon knife"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2962632413/50fx50f",
    spanText: "Ursus Knife",
    slug: "knife/ursus knife"
  }
],
glovesTypes: [
    {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2076470432/50fx50f",
        spanText: "Bloodhound Gloves",
        slug: "gloves/bloodhound gloves"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141786279/50fx50f",
        spanText: "Broken Fang Gloves",
        slug: "gloves/broken fang gloves"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2076468094/50fx50f",
        spanText: "Driver Gloves",
        slug: "gloves/driver gloves"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2077650354/50fx50f",
        spanText: "Hand Wraps",
        slug: "gloves/hand wraps"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2735429295/50fx50f",
        spanText: "Hydra Gloves",
        slug: "gloves/hydra gloves"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2076503795/50fx50f",
        spanText: "Moto Gloves",
        slug: "gloves/moto gloves"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2077239730/50fx50f",
        spanText: "Specialist Gloves",
        slug: "gloves/specialist gloves"
      },
      {
        imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/2076475516/50fx50f",
        spanText: "Sport Gloves",
        slug: "gloves/sport gloves"
      }
],
machineGunsTypes: [
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1310007376/50fx50f",
      spanText: "M249",
      slug: "machine gun/m249"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934501/50fx50f",
      spanText: "Negev",
      slug: "machine gun/negev"
    }
  ],
smgsTypes: [
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1023129538/50fx50f",
      spanText: "MAC-10",
      slug: "smg/mac-10"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3035567651/50fx50f",
      spanText: "MP5-SD",
      slug: "smg/mp5-sd"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011940678/50fx50f",
      spanText: "MP7",
      slug: "smg/mp7"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934619/50fx50f",
      spanText: "MP9",
      slug: "smg/mp9"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011938044/50fx50f",
      spanText: "P90",
      slug: "smg/p90"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1440503205/50fx50f",
      spanText: "PP-Bizon",
      slug: "smg/pp-bizon"
    },
    {
      imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011934629/50fx50f",
      spanText: "UMP-45",
      slug: "smg/ump-45"
    }
  ],
shotgunsTypes: [
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1309988349/50fx50f",
    spanText: "MAG-7",
    slug: "shotgun/mag-7"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1011938322/50fx50f",
    spanText: "Nova",
    slug: "shotgun/nova"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1440502939/50fx50f",
    spanText: "Sawed-Off",
    slug: "shotgun/sawed-off"
  },
  {
    imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1309988217/50fx50f",
    spanText: "XM1014",
    slug: "shotgun/xm1014"
  }
],
// agentsTypes: [
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141780207/50fx50f",
//       spanText: "'Blueberries' Buckshot"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578725140/50fx50f",
//       spanText: "'Medium Rare' Crasswater"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084106/50fx50f",
//       spanText: "'The Doctor' Romanov"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084078/50fx50f",
//       spanText: "'Two Times' McCoy"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141778898/50fx50f",
//       spanText: "1st Lieutenant Farlow"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084295/50fx50f",
//       spanText: "3rd Commando Company"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724250/50fx50f",
//       spanText: "Arno The Overgrown"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578727428/50fx50f",
//       spanText: "Aspirant"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608083916/50fx50f",
//       spanText: "B Squadron Officer"
//     },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141783687/50fx50f",
//     //   spanText: "Bio-Haz Specialist"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084108/50fx50f",
//     //   spanText: "Blackwolf"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724251/50fx50f",
//     //   spanText: "Bloody Darryl The Strapped"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084775/50fx50f",
//     //   spanText: "Buckshot"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724344/50fx50f",
//     //   spanText: "Chef D'Escadron Rouchard"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724254/50fx50f",
//     //   spanText: "Chem-Haz Capitaine"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141782094/50fx50f",
//     //   spanText: "Chem-Haz Specialist"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724445/50fx50f",
//     //   spanText: "Cmdr. Davida 'Goggles' Fernandez"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578725471/50fx50f",
//     //   spanText: "Cmdr. Frank 'Wet Sox' Baroud"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141780069/50fx50f",
//     //   spanText: "Cmdr. Mae 'Dead Cold' Jamison"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578726350/50fx50f",
//     //   spanText: "Col. Mangos Dabisi"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724346/50fx50f",
//     //   spanText: "Crasswater The Forgotten"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578726804/50fx50f",
//     //   spanText: "D Squadron Officer"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084818/50fx50f",
//     //   spanText: "Dragomir"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724107/50fx50f",
//     //   spanText: "Elite Trapper Solman"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084479/50fx50f",
//     //   spanText: "Enforcer"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141786221/50fx50f",
//     //   spanText: "Getaway Sally"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084516/50fx50f",
//     //   spanText: "Ground Rebel"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141793175/50fx50f",
//     //   spanText: "John 'Van Healen' Kask"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578727914/50fx50f",
//     //   spanText: "Jungle Rebel"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578725688/50fx50f",
//     //   spanText: "Lieutenant 'Tree Hugger' Farlow"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578724252/50fx50f",
//     //   spanText: "Lieutenant Rex Krikey"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141779218/50fx50f",
//     //   spanText: "Little Kev"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084263/50fx50f",
//     //   spanText: "Lt. Commander Ricksaw"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084872/50fx50f",
//     //   spanText: "Markus Delrow"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084014/50fx50f",
//     //   spanText: "Maximus"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084982/50fx50f",
//     //   spanText: "Michael Syfers"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4141779051/50fx50f",
//     //   spanText: "Number K"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/4578729859/50fx50f",
//     //   spanText: "Officer Jacques Beltram"
//     // },
//     // {
//     //   imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3608084119/50fx50f",
//     //   spanText: "Operator"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Osiris"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Primeiro Tenente"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Prof. Shahmat"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Rezan The Ready"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Rezan The Redshirt"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Safecracker Voltzmann"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Seal Team 6 Soldier"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sergeant Bombson"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sir Bloody Darryl Royale"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sir Bloody Loudmouth Darryl"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sir Bloody Miami Darryl"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sir Bloody Silent Darryl"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sir Bloody Skullhead Darryl"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Slingshot"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Soldier"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Sous-Lieutenant Medic"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Special Agent Ava"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Street Soldier"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "The Elite Mr. Muhlik"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Trapper"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Trapper Aggressor"
//     // },
//     // {
//     //   imgSrc: "https://waxpeer.com/img/loading.svg",
//     //   spanText: "Vypa Sista Of The Revolution"
//     // }
//   ],
// otherTypes: [
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1354151432/50fx50f",
//       spanText: "Autograph Capsule"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1325736165/50fx50f",
//       spanText: "Music Kit"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/3729327619/50fx50f",
//       spanText: "Patch"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1989273796/50fx50f",
//       spanText: "Sealed Graffiti"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/1225721985/50fx50f",
//       spanText: "Sticker"
//     },
//     {
//       imgSrc: "https://steamcommunity-a.akamaihd.net/economy/image/class/730/721248158/50fx50f",
//       spanText: "Key"
//     }
//   ],}
}