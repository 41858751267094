// Header.js
import React from 'react';
import MetamaskLogin from './MetamaskLogin';
import SubNav from './SubNav';
import SearchBox from './SearchBox';
import SmallNav from './SmallNav';
import { mainTypes } from './constant';

const Header = () => {
    const data = mainTypes

  return (
    <div className="flex justify-between items-center fixed top-0 left-0 right-0 z-50 px-12 h-16 bg-[#202020]">
      <div className="flex items-center h-full">
        <a href="/">
          <img className="h-12 hidden lg:block min-w-[100px]" src="/img/logo.png" alt="logo" />
          <img className="h-12 lg:hidden" src="/img/favicon.png" alt="favicon" />
        </a>
        <ul className="hidden lg:flex pl-8 font-normal">
          <li>
            <a className="text-gray-400 p-5 hover:text-white" href="/">
              Marketplace
            </a>
          </li>
        </ul>
      </div>
    

          <div className="z-20 bg-[#202020] w-100 hidden 2xl:flex justify-center items-center">
                {data.map((item) => {
                    return (
                        <SubNav item={item} key={item.name}/>
                    )
                })}
            </div>



            <div className="flex items-center gap-0">
      <SearchBox />

        <div className="rounded-sm flex items-center gap-2 bg-primary text-white max-w-[250px] h-10 px-4">
        {/* {userAddress == null && <MetamaskLogin onLogin={handleLogin} />} */}
      {/* {userAddress && <p>...{userAddress.slice(-8)}</p>} */}
      <MetamaskLogin />
        </div>
        <div className="flex items-center justify-center h-8 w-8 rounded-full overflow-hidden hidden 2xl:block">
      <img src="https://e7.pngegg.com/pngimages/84/165/png-clipart-united-states-avatar-organization-information-user-avatar-service-computer-wallpaper-thumbnail.png" alt="User Avatar" className="h-full w-full object-cover" />
    </div>
    <SmallNav />
      </div>
    </div>
  );
};

export default Header;
