export const itemsReducer = (state = [], action) => {
    switch (action.type) {
        case 'UPDATE_ITEMS_LIST':            
            return action.payload;
        case 'ADD_ITEMS_LIST':
            return [...state, ...action.payload];
        default:
            return state;
    }
}

export default itemsReducer;