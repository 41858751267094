import React from "react";
import { useSelector } from "react-redux";
import Card from "./Card";
import { useDispatch } from 'react-redux';
import { addItemsList } from './actions/items';
import { updateCursor } from './actions/cursor';
import { useEffect } from 'react';
import { baseAPI } from "./constant";


const Items = () => {
    const items = useSelector((state) => state.items);     
    const dispatch = useDispatch();
    const cursor = useSelector((state) => state.cursor);
    const price = useSelector((state) => state.price|| {
      min: 0,
      max: 10000,
    });
    let minPrice, maxPrice;
    if (price) {
      minPrice = price.min;
      maxPrice = price.max;
    }
    const searchKeyword = useSelector((state) => state.search|| '');
    const exterior = useSelector((state) => state.exterior || '');
    const type = useSelector((state) => state.type || '');
    

  useEffect(() => {
    const handleScroll = async () => {

      // Get the current scroll position
      const scrollPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

      // Get the maximum scroll height
      const scrollHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );

      let treeFilters = "";
            if (exterior !== '') {
              treeFilters = `&treeFilters=${exterior}`;
            }else if (type !== '') {
              treeFilters = `&treeFilters=${type}`;
            }else {
              treeFilters = `&treeFilters=${exterior},${type}`;
            }

      // Check if the user is at the bottom of the component
      if (scrollPosition + window.innerHeight >= scrollHeight) {
        const fetchData = async () => {
            console.log('fetching dataaaaaa in MBody ', cursor);
            if (cursor === "W10="){
                return;
            }
          try {
            const response = await fetch(`${baseAPI}&title=${searchKeyword}&priceFrom=${minPrice}&priceTo=${maxPrice}&cursor=${cursor}${treeFilters}`);
            console.log("fetch 2")

            const data = await response.json();
            console.log(`fetching data3: , ${cursor}` , data);
    
            if (response.status === 200) {
              dispatch(addItemsList(data.objects))
              dispatch(updateCursor(data.cursor));
            } else {
              console.error('Error fetching data01:', data);
            }
          } catch (error) {
            console.error('Error fetching data2:', error);
          }
        };
    
        fetchData();
      }
    };

    // Add event listener to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch, cursor, minPrice, maxPrice, searchKeyword, type, exterior]); // Empty dependency array to ensure the effect runs only once on component mount
 

    return (
        <div className="grid grid-cols-1 max-w-7xl md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 sm:ml-80">
          {items.map((item) => (
              <Card key={item.itemId} itemData={item} />
            ))}
        </div>
    )
}

export default Items;