// SearchBox.jsx
import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateItemsList } from './actions/items';
import { updateCursor } from './actions/cursor';
import { updateSearch } from './actions/search';
import { baseAPI } from './constant';
import { useSelector } from 'react-redux';
import { updateLoading } from './actions/loading';

const SearchBox = () => {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const price = useSelector((state) => state.price|| {
    min: 0,
    max: 1000000,
  });
  let minPrice, maxPrice;
  if (price) {
    minPrice = price.min;
    maxPrice = price.max;
  }
  const exterior = useSelector((state) => state.exterior || '');
  const type = useSelector((state) => state.type || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateLoading(true));
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: adds a smooth scrolling animation
    });

    const fetchData = async () => {
      let treeFilters = "";
      if (exterior !== '') {
        treeFilters = `&treeFilters=${exterior}`;
      }else if (type !== '') {
        treeFilters = `&treeFilters=${type}`;
      }else {
        treeFilters = `&treeFilters=${exterior},${type}`;
      }

      try {
        const response = await fetch(`${baseAPI}&title=${value}&priceFrom=${minPrice}&priceTo=${maxPrice}${treeFilters}`);
        console.log("fetch 3")
        
        const data = await response.json();

        if (response.status === 200) {
          dispatch(updateItemsList(data.objects));
          dispatch(updateSearch(value));
          dispatch(updateCursor(data.cursor));
          dispatch(updateLoading(false));
        } else {
          console.error('Error fetching data4:', data);
        }
      } catch (error) {
        console.error('Error fetching data5:', error);
      }
    };

    fetchData();
  }

  return (
    <form className="relative hidden lg:block" onSubmit={handleSubmit}>  
      <input
        type="text"
        placeholder="Search..."
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-10 rounded-full text-sm focus:outline-none"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <button className="absolute right-0 top-0 mt-3 mr-4">
        {/* Add your search icon here, e.g., an SVG or Font Awesome icon */}
        <svg
          className="h-4 w-4 text-gray-500"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M21 21l-5-5m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </button>
    </form>
  );
};

export default SearchBox;
