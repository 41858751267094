import React from 'react';

const Footer = () => {
  return (
    <div className="hidden lg:block fixed bottom-0 left-0 z-10 w-full h-10 bg-[#303030]">
      <div className="flex justify-between items-center h-full">
        <div className="flex">
          <div className="flex items-center gap-4 text-white font-medium text-xs px-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-life-buoy w-4 h-4"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="m4.93 4.93 4.24 4.24"></path>
              <path d="m14.83 9.17 4.24-4.24"></path>
              <path d="m14.83 14.83 4.24 4.24"></path>
              <path d="m9.17 14.83-4.24 4.24"></path>
              <circle cx="12" cy="12" r="4"></circle>
            </svg>{' '}
            <span>Support</span>
          </div>
          <ul className="border-r border-[#5B5B66] flex">
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                FAQ
              </a>
            </li>
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                API
              </a>
            </li>
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                Extensions
              </a>
            </li>
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                Developers
              </a>
            </li>
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                AMI Check
              </a>
            </li>
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                Privacy Policy
              </a>
            </li>
            <li>
              <a className="block text-[#ababab] font-medium text-xs px-5 py-[10px]" href="/">
                Terms of Use
              </a>
            </li>
          </ul>
          <ul className="flex px-6">
          <li>
              <a className="flex justify-center items-center w-[30px] h-full" href="https://www.facebook.com/csgoexc.official/" target="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-facebook fill-[#bfbfbf] w-4 h-4"
                >
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                </svg>
              </a>
            </li>
            <li>
              <a className="flex justify-center items-center w-[30px] h-full" href="https://youtube.com/@TDMHeyzeusClips" target="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-youtube fill-[#bfbfbf] w-4 h-4"
                >
                  <path d="M2.5 17a24.12 24.12 0 0 1 0-10 2 2 0 0 1 1.4-1.4 49.56 49.56 0 0 1 16.2 0A2 2 0 0 1 21.5 7a24.12 24.12 0 0 1 0 10 2 2 0 0 1-1.4 1.4 49.55 49.55 0 0 1-16.2 0A2 2 0 0 1 2.5 17"></path>
                  <path d="m10 15 5-3-5-3z"></path>
                </svg>
              </a>
            </li>
            <li>
              <a className="flex justify-center items-center w-[30px] h-full" href="https://twitter.com/csgoexc" target="/">
                {/* Add Twitter icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-twitter fill-[#bfbfbf] w-4 h-4"
                >
                  <path d="M23 3a10.94 10.94 0 0 1-3.14 0 5.47 5.47 0 0 0 2.39-3 11 11 0 0 1-3.46 1.33 5.5 5.5 0 0 0-9.37 5.01 6.26 6.26 0 0 0 0.15 1.35A15.6 15.6 0 0 1 2 3.13a5.49 5.49 0 0 0 1.7 7.33A5.41 5.41 0 0 1 2 10.92v0.07a5.5 5.5 0 0 0 4.42 5.39c-0.41 0.09-0.84 0.14-1.27 0.17a5.49 5.49 0 0 0 5.11 3.81 11.04 11.04 0 0 1-7.78 2 15.51 15.51 0 0 0 8.4 2.45c10.11 0 15.6-8.38 15.6-15.6 0-0.24 0-0.48 0-0.71a11.18 11.18 0 0 0 2.94-2.95l0.01-0.02z"></path>
                </svg>
              </a>
            </li>
            <li>
              <a className="flex justify-center items-center w-[30px] h-full" href="https://www.instagram.com/csgoexc" target="/">
                {/* Add Instagram icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-instagram fill-[#bfbfbf] w-4 h-4"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11a2 2 0 1 1-2-2 2 2 0 0 1 2 2z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Footer;
