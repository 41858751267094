import { combineReducers } from "redux";
import items from "./items";
import signer from "./signer";
import cursor from "./cursor";
import search from "./search";
import price from "./price";
import exterior from "./exterior";
import types from "./types";
import loading from "./loading";


const allReducers = combineReducers({
  items,
  signer,
  cursor,
  search,
  price,
  exterior,
  types,
  loading
});

export default allReducers;