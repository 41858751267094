// Sidebar.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateItemsList } from './actions/items';
import { updateCursor } from './actions/cursor';
import { updatePrice } from './actions/price';
import { baseAPI } from './constant';
import { updateExterior } from './actions/exterior';
import { updateLoading } from './actions/loading';

const Sidebar = () => {
  const [showGames, setShowGames] = useState(true);
  const [showPrice, setShowPrice] = useState(true);
  const [showExterior, setShowExterior] = useState(true);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [exterior, setExterior] = useState({
    all: true,
    factoryNew: true,
    minimalWear: true,
    fieldTested: true,
    wellWorn: true,
    battleScarred: true
  });

  const dispatch = useDispatch();

  const searchKeyword = useSelector((state) => state.search|| '');
  const type = useSelector((state) => state.type || '');
  const handleExteriorSubmit = (e) => {
    dispatch(updateLoading(true));

    setExterior(e)
    console.log('exterior', e)
    let exterior = "";
    if (e.all === true) {
      exterior = "";
    } else{
      if (e.factoryNew === true) {
        exterior = "exterior%5B%5D=factory new";
      }
      if (e.minimalWear === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=minimal wear" : "exterior%5B%5D=minimal wear";
      }
      if (e.fieldTested === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=field-tested" : "exterior%5B%5D=field-tested";
      }
      if (e.wellWorn === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=well-worn" : "exterior%5B%5D=well-worn";
      }
      if (e.battleScarred === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=battle-scarred" : "exterior%5B%5D=battle-scarred";
      }
    }

    let treeFilters = exterior === '' ? `categoryPath[]=${type}` : `${exterior},categoryPath[]=${type}`;

    const fetchData = async () => {
      try {
        const response = await fetch(`${baseAPI}&title=${searchKeyword}&priceFrom=${minPrice}&priceTo=${maxPrice}&treeFilters=${treeFilters}`);
        console.log("fetch 4")
        const data = await response.json();

        if (response.status === 200) {
          dispatch(updateItemsList(data.objects));
          dispatch(updatePrice(minPrice, maxPrice));
          dispatch(updateCursor(data.cursor));
          dispatch(updateExterior(exterior));
          console.log('Data fetched:', data);
          dispatch(updateLoading(false));
        } else {
          console.error('Error fetching data6:', data);
        }
      } catch (error) {
        console.error('Error fetching data7:', error);
      }
    };

    fetchData();
  }

  const handleGamesClick = () => {
    setShowGames(!showGames);
  };

  const handlePriceClick = () => {
    setShowPrice(!showPrice);
  };

  const handleExteriorClick = (e) => {
    setShowExterior(!showExterior);
  }

  const onChangeMinPrice = (e) => {
    setMinPrice(e.target.value);
  }

  const onChangeMaxPrice = (e) => {
    setMaxPrice(e.target.value);
  }

  const handleClickPrice = (e) => {
    e.preventDefault();
    dispatch(updateLoading(true));

    let minPrice = document.getElementById('minPrice').value;
    let maxPrice = document.getElementById('maxPrice').value;

    let exterior = "";
    if (e.all === true) {
      exterior = "";
    } else{
      if (e.factoryNew === true) {
        exterior = "exterior%5B%5D=factory new";
      }
      if (e.minimalWear === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=minimal wear" : "exterior%5B%5D=minimal wear";
      }
      if (e.fieldTested === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=field-tested" : "exterior%5B%5D=field-tested";
      }
      if (e.wellWorn === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=well-worn" : "exterior%5B%5D=well-worn";
      }
      if (e.battleScarred === true) {
        exterior = exterior !== "" ? exterior + ",exterior%5B%5D=battle-scarred" : "exterior%5B%5D=battle-scarred";
      }
    }

    let treeFilters = "";
      if (exterior !== '') {
        treeFilters = `&treeFilters=${exterior}`;
      }else if (type !== '') {
        treeFilters = `&treeFilters=${type}`;
      }else {
        treeFilters = `&treeFilters=${exterior},${type}`;
      }

    const fetchData = async () => {
      try {
        const response = await fetch(`${baseAPI}&title=${searchKeyword}&priceFrom=${minPrice*100}&priceTo=${maxPrice*100}${treeFilters}`);
        console.log("fetch 4")
        const data = await response.json();

        if (response.status === 200) {
          dispatch(updateItemsList(data.objects));
          dispatch(updatePrice(minPrice*100, maxPrice*100));
          dispatch(updateCursor(data.cursor));
          console.log('Data fetched:', data);
          dispatch(updateLoading(false));
        } else {
          console.error('Error fetching data6:', data);
        }
      } catch (error) {
        console.error('Error fetching data7:', error);
      }
    };

    fetchData();
  }

  return (
    <div className="bg-[#202020] h-full text-white p-4 w-80 fixed left-0 top-16 bottom-0 mt-2 hidden sm:block">
      <div className="cursor-pointer flex items-center justify-between" onClick={handleGamesClick}>
        <span>Games</span>
        <span className={`ml-2 transform ${showGames ? 'rotate-90' : 'rotate-0'}`}>➤</span>
      </div>
      {showGames && (
        <div className="mt-2 flex items-center">
          <div className="mr-4">
            <img className="h-60" src="/img/pubg.jpg" alt="PUBG Logo" />
            <div className="text-center mt-1">PUBG</div>
          </div>
          <div>
            <img className="h-60" src="/img/csgo.jpg" alt="CS:GO Logo" />
            <div className="text-center mt-1">CS:GO</div>
          </div>
        </div>
      )}

<div className="cursor-pointer flex items-center justify-between mt-6" onClick={handlePriceClick}>
        <span>Price</span>
        <span className={`ml-2 transform ${showPrice ? 'rotate-90' : 'rotate-0'}`}>➤</span>
      </div>
      {showPrice && (
        <form className="mt-2 flex items-center justify-between ml-4 mr-4" onSubmit={handleClickPrice}>
        {/* Min price input */}
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-400">
            $
          </span>
          <input
            type="text"
            placeholder="Min"
            className="border border-gray-400 rounded-sm w-24 h-10 pl-8 bg-gray-700"
            id="minPrice"
            value={minPrice}
            onChange={onChangeMinPrice}
          />
        </div>
  
        {/* Max price input */}
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-4 text-gray-400">
            $
          </span>
          <input
            type="text"
            placeholder="Max"
            className="border border-gray-400 rounded-sm w-24 h-10 pl-8 ml-2 bg-gray-700 webkit-appearance: none"
            id="maxPrice"
            value={maxPrice}
            onChange={onChangeMaxPrice}
          />
        </div>
        <input type="submit" value="Apply" className="rounded-sm text-white h-10 w-20 ml-2 px-4 hover:border-gray-400 hover:bg-gray-400 hover:text-black" onClick={handleClickPrice} />
        {/* <input
      type="submit"
      value=""
      className="rounded-sm h-10 w-20 ml-2"
      style={{ backgroundImage: `url('https://c1.klipartz.com/pngpicture/959/283/sticker-png-magnifying-glass-symbol-line-black-and-white-circle-thumbnail.png')`, backgroundSize: 'cover' }} */}
    {/* /> */}
      </form>
      )}

<div className="cursor-pointer flex items-center justify-between mt-6" onClick={handleExteriorClick}>
        <span>Exterior</span>
        <span className={`ml-2 transform ${showExterior ? 'rotate-90' : 'rotate-0'}`}>➤</span>
      </div>
      {showExterior && (
        <div className="mt-2 flex flex-col ml-4 mr-4">
        {/* input checkbox */}
        <div className="flex items-center mt-2 mb-2">
        <input type="checkbox" className="h-6 w-6 text-gray-700 rounded-lg" checked={exterior.all}  onClick={() => {
          // let e = exterior;
          // e.all = !e.all;
          // e.factoryNew = e.all;
          // e.minimalWear = e.all;
          // e.fieldTested = e.all;
          // e.wellWorn = e.all;
          // e.battleScarred = e.all;

          // 

          handleExteriorSubmit({
            all: !exterior.all,
            factoryNew: !exterior.all,
            minimalWear: !exterior.all,
            fieldTested: !exterior.all,
            wellWorn: !exterior.all,
            battleScarred: !exterior.all,
          })
        }}/>
        <label className="ml-2">All</label>
        </div>
        <div className="flex items-center mt-2 mb-2">
        <input type="checkbox" className="h-6 w-6 text-gray-700 rounded-lg" checked={exterior.factoryNew} onClick={() => {
          handleExteriorSubmit({
            all: false,
            factoryNew: !exterior.factoryNew,
            minimalWear: exterior.minimalWear,
            fieldTested: exterior.fieldTested,
            wellWorn: exterior.wellWorn,
            battleScarred: exterior.battleScarred,
          })
        }} />
        <label className="ml-2">Factory New</label>
        </div>
        <div className="flex items-center mt-2 mb-2">
        <input type="checkbox" className="h-6 w-6 text-gray-700 rounded-lg" checked={exterior.minimalWear} onClick={() => {
          handleExteriorSubmit({
            all: false,
            factoryNew: exterior.factoryNew,
            minimalWear: !exterior.minimalWear,
            fieldTested: exterior.fieldTested,
            wellWorn: exterior.wellWorn,
            battleScarred: exterior.battleScarred,
          })
        }}/>
        <label className="ml-2">Minimal Wear</label>
        </div>
        <div className="flex items-center mt-2 mb-2">
        <input type="checkbox" className="h-6 w-6 text-gray-700 rounded-lg" checked={exterior.fieldTested} onClick={() => {
          handleExteriorSubmit({
            all: false,
            factoryNew: exterior.factoryNew,
            minimalWear: exterior.minimalWear,
            fieldTested: !exterior.fieldTested,
            wellWorn: exterior.wellWorn,
            battleScarred: exterior.battleScarred,
          })
        }}/>
        <label className="ml-2">Field-Tested</label>
        </div>
        <div className="flex items-center mt-2 mb-2">
        <input type="checkbox" className="h-6 w-6 text-gray-700 rounded-lg" checked={exterior.wellWorn} onClick={() => {
          handleExteriorSubmit({
            all: false,
            factoryNew: exterior.factoryNew,
            minimalWear: exterior.minimalWear,
            fieldTested: exterior.fieldTested,
            wellWorn: !exterior.wellWorn,
            battleScarred: exterior.battleScarred,
          })
        }}/>
        <label className="ml-2">Well-Worn</label>
        </div>
        <div className="flex items-center mt-2 mb-2">
        <input type="checkbox" className="h-6 w-6 text-gray-700 rounded-lg" checked={exterior.battleScarred} onClick={() => {
          handleExteriorSubmit({
            all: false,
            factoryNew: exterior.factoryNew,
            minimalWear: exterior.minimalWear,
            fieldTested: exterior.fieldTested,
            wellWorn: exterior.wellWorn,
            battleScarred: !exterior.battleScarred,
          })
        }}/>
        <label className="ml-2">Battle-Scarred</label>
        </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
