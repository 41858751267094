import React from 'react';
import Header from './Header';
import Footer from './Footer';
import MBody from './MBody';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Detail from './Detail';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'ddd1d4b636e649425346a5ce99771067'

// 2. Set chains
const mainnet = {
  chainId: 56,
  name: 'Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
}

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://csgovine.com/',
  icons: ['https://csgovine.com/img/logo.png']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId
})


const App = () => {
  let routers = createBrowserRouter([
    { path: '/', element: <MBody/> },
    { path: '/items/:id', element: <Detail/> },
  ])

  return (
      <div className=" px-4 bg-[#1a1a1a]">
        <div className="absolute z-[-5] top-0 left-0 right-0 h-full bg-[#1a1a1a]">
        </div>
        <Header />
        <RouterProvider router={routers} />
        <Footer/>
      </div>
  );
};

export default App;
